import "./ShopBag.css";
import trolleyPicture from "../../images/trolleyPicture.png";
import bagLine from "../../images/bagLine.png";
import {Redirect, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import trash from "../../images/delete-24.png";
import backArrow from "../../images/backArrow.png";

const PayOrShopButtons = (props) => {
    let history = useHistory()
    const routeShop = () => {
        let path = "/shop"
        history.push(path);
    }
    const routeInvoice = () => {
        let path = "/shop/invoice"
        if (props.chosenItems.length > 0) {
            history.push(path);
        } else {
            alert("If you want to place an order, you need to select the items first.")
        }
    }
    return (
        <div className="payOrShopButtons">
            <button className="shopMore" onClick={routeShop}>Shop more</button>
            <button className="payButton" onClick={routeInvoice} onKeyDown={(e) => {
                if (e.code === "Enter") {
                    e.preventDefault()
                }
            }}>Continue
            </button>
        </div>
    )
}
// onClick={() => {
//     // axios.post('http://localhost:1337/payment/', {"chosenItems": props.chosenItems , "email": "siema@wp.pl"})
//     axios.post('https://backend-dot-kate-dev-308319.ey.r.appspot.com/payment/', {"chosenItems": props.chosenItems , "email": "karolkiljan@gmail.com"})
//         .then(response => {
//             console.log("siema" , response)
//             window.location.href = response.data.message.link
//         })

const TitleBag = () => {
    return (
        <div className="titleBar">
            <img className="trolleyPictureBag" src={trolleyPicture} alt=''/>
            <p>BAG</p>
        </div>
    )
}

const ChosenItemsList = (props) => {
    const [code, setCode] = useState('')
    const [wrong, setWrong] = useState(false)
    const [good, setGood] = useState(false)
    useEffect(() => {
    }, [good, wrong])
    const applyPromoCode = () => {
        // try {
        axios.post('https://backend-68zlq.ondigitalocean.app/payment/promo',
            {"code": code})
            .then((response) => {
                    if (response.status == 200) {
                        setWrong(false)
                        setGood(true)
                        props.setShopState(
                            {
                                ...props.shopState,
                                discount: {code: code, value: response.data.data.discount}
                            }
                        )
                    }
                }
            ).catch((err) => {
            setWrong(true)
            setGood(false)
            alert("Wrong code!")
            props.setShopState(
                {
                    ...props.shopState,
                    discount: {code: '', value: 0}
                }
            )
        })
        // } catch (err) {
        //     console.log("ERORS")
        //     setWrong(true)
        //     setGood(false)
        //     alert("Wrong code!")
        // }
    }
    return (
        <div className="chosenItemsListWrapper">
            <div className="itemListScrollBag">
                {props.shopState.chosenItems.map(p =>
                    <div className="shopBagRow"
                         onClick={() => {
                             props.setShopState(
                                 {
                                     ...props.shopState,
                                     chosenItems: props.shopState.chosenItems.filter(elem => elem.item.id !== p.item.id),
                                     // totalCost: props.shopState.totalCost - (Math.floor(p.item.price*(1 - props.shopState.discount.value)*100)/100)*p.count
                                 }
                             )
                         }}
                    >
                        <p className="chosenItemsListElementShop">{p.item.name} x{p.count} {((Math.floor(p.item.price * (100 - props.shopState.discount.value * 100)) / 100) * p.count).toFixed(2)} zł</p>
                        <img src={trash} className="trashImage"/>
                    </div>)}
            </div>
            <div className="totalCostBag">
                <p>Total
                    cost: {props.shopState.totalCost.toFixed(2)} zł {props.shopState.discount.value > 0 ? `(-${props.shopState.discount.value * 100}%)` : ""}
                </p>
                {/*<div className="totalCostRow">*/}
                {/*    <p>Total cost: </p>*/}
                {/*    <p className="lineThrough">siema</p>*/}
                {/*    <p>{props.shopState.totalCost.toFixed(2)} zł {props.shopState.discount.value > 0 ? `(-${props.shopState.discount.value*100}%)`: ""}</p>*/}
                {/*</div>*/}
                <div className="promoStyle">
                    <div className="promoRow">
                        <label>PROMO CODE:</label>
                        <input style={{"border": good ? " 2px ridge Green" : " 2px ridge #222222"}} onChange={(e) => {
                            setCode(e.target.value)
                        }} placeholder={props.shopState.discount.code}/>
                    </div>
                    <button className="promoButton" onClick={applyPromoCode}>Apply</button>
                </div>
            </div>
        </div>
    )
}

const ShopBag = (props) => {
    let history = useHistory();

    useEffect(() => {
    }, [])
    return (
        <div className="shopBagStyle">
            <div className="shopBagWrapper">
                <img className="bagLine" src={bagLine}/>
                <div className="bag">
                    <div className="titleAndListWrapper">
                        <TitleBag/>
                        <ChosenItemsList
                            shopState={props.shopState}
                            setShopState={props.setShopState}
                        />
                    </div>
                    <PayOrShopButtons chosenItems={props.shopState.chosenItems}/>
                </div>
            </div>
            <img src={backArrow} className="BackButton" onClick={() => history.push("/shop")}/>
        </div>
    )
}

export default ShopBag
