import {Link as LinkRouter, Route, Switch, useHistory, useParams} from "react-router-dom";
import "./Blog.css"
import blogPicture from "../../images/blogPicture.png"
import blogPicture2 from "../../images/blogPicture2.png"
import example from "../../images/backgroundBlogDetails.png"
import welcomeTextImage from "../../images/getInspiredWelcomeText.png"
import {Link as LinkScroll, Element} from 'react-scroll'
import {useState, useEffect, useRef} from "react";
import {useRouteMatch} from "react-router";
import {fetchItem, fetchPost, fetchPosts} from "../../FetchData";
import loading from "../../images/loading.GIF";
import backArrow from "../../images/backArrow.png";

const createBlogList = (posts) => {
    // let len = posts.length
    // let lasts = len - Math.floor(len)
    // let listOfLists = Array()
    // for (let i = 0; i < Math.floor(len) / 3; i++) {
    //     listOfLists.push(Array(posts[i * 3], posts[i * 3 + 1], posts[i * 3 + 2]))
    // }
    // if (lasts !== 0) {
    //     if (lasts === 1) {
    //         listOfLists.push(Array(posts[len - 1]))
    //     }
    //     listOfLists.push(Array(posts[len - 2], posts[len - 1]))
    // }
    return (
        posts.map((elem) => (<BlogMiniatureElement data={elem} />))
    )
}

const BlogMiniatureElement = (props) => {
    const [mouse, setMouse] = useState(true)
    const {data} = props
    useEffect(() => {
    }, [mouse])
    if (mouse) {
        return <LinkRouter className="blogItem" onMouseEnter={() => {
            setMouse(false)
        }} onTouchStart={() => {
            setMouse(false)}}
                           to={`/${data.id}`}>
            <img className="blogImage" src={data.miniatureImage[0].formats.medium.url} alt={data.miniatureText}/>
        </LinkRouter>
    } else {
        return <LinkRouter className="blogItem" onMouseLeave={() => {
            setMouse(true)
        }} onTouchEnd={() => {
            setMouse(true)
        }} to={`/${data.id}`}>
            <img className="blogImage" src={data.miniatureImage[1].formats.medium.url} alt={data.miniatureText}/>
        </LinkRouter>
    }
}

const BlogDetails = ({data}) => {
    const {id} = useParams()
    // const item = data.find(p => p.id === Number(id))
    const [item, setItem] = useState({})
    const [loaded, setLoad] = useState(false)
    let history = useHistory();
    useEffect(() => {
        fetchPost(id).then((response) =>{
                setItem(response)
                setLoad(true)
            }
        )
        // console.log("asdasdasdPPPPPP")
    }, [])
    return (
        loaded? (
            <div className="blogDetails blogDetailsBackground" style={
                item.postBackground !== null ? {"backgroundImage": `url(${item.postBackground.url})`} : {"background-color": "#222222"}
            }>
                <div className="blogContent">
                    <div className="titleComponent">
                        <img src={item.title.url} alt=""/>
                    </div>
                    <div className="textComponent">
                        <p>{item.postContent}</p>
                    </div>
                    <img src={backArrow} className="BackButton" onClick={() => history.push("/")} />
                </div>
            </div> )
            : null
    )
}


const Blog = () => {
    const [posts, setPosts] = useState([])

    const sortDate = (a,b) => {
        if(Date.parse(a.published_at) < Date.parse(b.published_at)){
            return 1
        }
        if(Date.parse(a.published_at) > Date.parse(b.published_at)){
            return -1
        }
        return 0

    }

    useEffect(() => {
        if (posts.length === 0){
            fetchPosts().then((data) => {
                    setPosts(data.sort(sortDate))
                }
            )
        }
    }, [])
    useEffect(() => {}, [posts])
    return (
        <Switch>
            <Route path="/:id">
                <BlogDetails data={posts}/>
            </Route>
            <Route path="/">
                <div className="blogStyle blogBackground">
                    <img className="welcomeTextImage" src={welcomeTextImage}/>
                    <div className="blogContainerStyle">
                        {posts !==[] ? createBlogList(posts): <img className="loadingGif" src={loading} alt="loading..." />}
                    </div>
                </div>
            </Route>
        </Switch>
    )
}
export default Blog;
