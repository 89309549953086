import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation, Link,
} from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './App.css';
import "./content/navbar/CustomNavbar.css"
import "./content/navbar/HeaderShoppingField.css"
import HeadBar from "./content/navbar/HeadBar";
import ig from "./images/igHead.png";
import fb from "./images/fbHead.png";
import pinterest from "./images/pinterestHead.png";
import trolleyPicture from './images/trolleyPicture.png';
import trash from './images/delete-24.png'

import useWindowDimensions from "./windowSizeFunction";
import Blog from "./content/blog/Blog";
import Shop from "./content/shop/Shop";
import SendMessage from "./content/contact/SendMessage";
import {useEffect, useState} from "react";
import Terms from "./content/termsAndPrivacy/Terms";
import Privacy from "./content/termsAndPrivacy/Privacy";
import "./content/termsAndPrivacy/termsAndPrivacy.css"
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// Initialize google analytics page view tracking
ReactGA.initialize('UA-196218944-1');
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});



const App = () => {
    const parsedTotalCost = Number(localStorage.getItem("totalCost") || 0)
    const parsedChosenItems = JSON.parse(localStorage.getItem("chosenItems")) || []
    const parsedDiscount = JSON.parse(localStorage.getItem("discount")) || {code: "", value: 0}
    const [shoppingData, setShoppingData] = useState({
        chosenItems: parsedChosenItems,
        totalCost: parsedTotalCost,
        discount: parsedDiscount
    })
    return (
        <Router history={history}>
            <div className="appStyle">
                <HeadBar/>
                {useWindowDimensions().width < 1200 ? <CustomNav/> : null}
                <CustomShopping shoppingData={shoppingData}/>
                <MessengerCustomerChat
                    pageId="103747527678163"
                    appId="477731316787855"
                />
                <Switch>
                    <Route path="/">
                        <Content setShoppingData={(data) => (setShoppingData({...data}))}/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
        </Router>
    )
}

const Content = (props) => {
    let location = useLocation()
    return (
        <Switch location={location}>
            <Route key={'/shop'} path='/shop'>
                <div className="contentWrapperStyle">
                    <Shop setShoppingData={props.setShoppingData}/>
                </div>
            </Route>
            <Route key={'/contact'} path='/contact'>
                <div className="contentWrapperStyle">
                    <SendMessage/>
                </div>
            </Route>
            <Route key={'/terms'} path='/terms'>
                <div className="contentWrapperStyle">
                    <Terms/>
                </div>
            </Route>
            <Route key={'/privacy'} path='/privacy'>
                <div className="contentWrapperStyle">
                    <Privacy/>
                </div>
            </Route>
            <Route key={'/'} path='/'>
                <div className="contentWrapperStyle">
                    <Blog/>
                </div>
            </Route>
        </Switch>
    )
}

const CustomNav = () => {
    const [clicked, setClicked] = useState(false)
    return (
        <nav role="navigation">
            <div id="menuToggle">
                <input type="checkbox" checked={clicked} onChange={() => setClicked(!clicked)}/>

                <span></span>
                <span></span>
                <span></span>

                <ul id="menu">
                    <li onClick={() => setClicked(!clicked)}><Link className="blog" to={{pathname: "/"}}>BLOG</Link>
                    </li>
                    <li onClick={() => setClicked(!clicked)}><Link className="shop" to="/shop">SHOP</Link></li>
                    <li onClick={() => setClicked(!clicked)}><Link className="contact" to="/contact">CONTACT</Link></li>
                    <a href="https://www.instagram.com/challenger.by.kate/">
                        <img className="icon" src={ig} onClick={() => {
                        }}/>
                    </a>
                    <a href="https://www.facebook.com/CHALLENGERbyKate/">
                        <img className="icon" src={fb}/>
                    </a>
                    <a href="https://pl.pinterest.com/challengerbykate/">
                        <img className="icon" src={pinterest}/>
                    </a>
                </ul>
            </div>
        </nav>
    )
}

const CustomShopping = (props) => {
    const location = useLocation();
    const [clicked, setClicked] = useState(false)
    const [data, setData] = useState({chosenItems: props.shoppingData.chosenItems, totalCost: props.shoppingData.totalCost, discount: props.shoppingData.discount})
    useEffect(() => {
        setData(
            {
                chosenItems: props.shoppingData.chosenItems,
                totalCost: props.shoppingData.totalCost,
                discount: props.shoppingData.discount
            }
        )
    }, [props.shoppingData.chosenItems, props.shoppingData.discount])
    return (
        <nav role="navigation">
            <div id="shopMenuToggle">
                <p className="totalCostText">{data.totalCost.toFixed(2)} zł</p>
                <input type="checkbox" checked={clicked} onChange={() => {
                    setClicked(!clicked)
                }
                }/>
                <img className="navbarTrolley" src={trolleyPicture} alt=""/>
                <ul id="menuShop">
                    <li onClick={() => setClicked(!clicked)}>
                        <Link className="customNavbarEdit"
                              style={location.pathname === "/shop/bag" ? {"pointer-events": "none"} : {}}
                              to={{pathname: "/shop/bag"}}>
                            EDIT BAG
                        </Link>
                    </li>
                    <li>
                        <div className="itemListScroll">
                            {data.chosenItems.map(p =>
                                <div className="shopRow"
                                    // onClick={() => {
                                    //     setData(
                                    //         {
                                    //             chosenItems: data.chosenItems.filter(elem => elem.item.id !== p.item.id),
                                    //             totalCost: data.totalCost - p.item.price*p.count
                                    //         }
                                    //     )
                                    // }}
                                >
                                    <p className="chosenItemsListElementShopHead">{p.item.name} x{p.count}</p>
                                    <img src={trash} className="trashImage"/>
                                </div>)}
                        </div>
                        <p className="totalCostMargin">Total
                            cost: {data.totalCost.toFixed(2)} zł {data.discount.value > 0 ? `(-${data.discount.value*100}%)`: ""}</p>
                    </li>
                    <li onClick={() => setClicked(!clicked)}>
                        <Link className="customNavbarOrder"
                              style={location.pathname === "/shop/bag" ? {"pointer-events": "none"} : {}}
                              to={{pathname: "/shop/bag"}}>Order Now!</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

const Footer = () => {
    const location = useLocation()
    return (
        <div className="footer">
            <Link className="footerMargin" style={location.pathname === "/terms" ? {"pointer-events": "none"} : {}}
                  to={{pathname: "/terms"}}> Terms & Conditions </Link>
            <p className="footerMargin"> | </p>
            <Link className="footerMargin" style={location.pathname === "/privacy" ? {"pointer-events": "none"} : {}}
                  to={{pathname: "/privacy"}}> Privacy Policy </Link>
            {
                useWindowDimensions().width > 337?
                    <>
                        <p className="footerMargin"> | </p>
                        <a href="https://www.instagram.com/challenger.by.kate/">
                            <img className="iconFooter" src={ig} onClick={() => {
                            }}/>
                        </a>
                        <a href="https://www.facebook.com/CHALLENGERbyKate/">
                            <img className="iconFooter" src={fb}/>
                        </a>
                        <a href="https://pl.pinterest.com/challengerbykate/">
                            <img className="iconFooter" src={pinterest}/>
                        </a>
                    </>: null
            }
        </div>
    )
}

export default App;
