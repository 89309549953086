import {useState} from "react";
import "./HeadBar.css"
import {Link, useHistory, useLocation} from "react-router-dom";
import fb from "../../images/fbHead.png"
import ig from "../../images/igHead.png"
import pinterest from "../../images/pinterestHead.png"
import challenger from "../../images/moutain.png"
import useWindowDimensions from "../../windowSizeFunction";

const HeadBar = () => {
    const location = useLocation();
    let history = useHistory()
    const routeChange = () => {
        let path = "/"
        history.push(path);
    }
    return (
        useWindowDimensions().width > 1200 ? (
                <div className="headStyle">
                    <div className="navigation">
                        <Link className="blog" to={{pathname: "/"}}>BLOG</Link>
                        <Link className="shop" to="/shop">SHOP</Link>
                        <Link className="contact" to="/contact">CONTACT</Link>
                    </div>
                    <img className="imageInHead d-none d-lg-block" src={challenger}
                         style={location.pathname === "/" ? {"pointer-Events": "none"} : {}}
                         onClick={routeChange}/>
                    <div className="socials">
                        <a className="socialsBox" href="https://www.instagram.com/challenger.by.kate/">
                            <img className="icon" src={ig}/>
                        </a>
                        <a className="socialsBox" href="https://www.facebook.com/CHALLENGERbyKate/">
                            <img className="icon" src={fb}/>
                        </a>
                        <a className="socialsBox" href="https://pl.pinterest.com/challengerbykate/">
                            <img className="icon" src={pinterest}/>
                        </a>
                    </div>
                </div>
            ) :
            (<div className="headStyle">
                <img className="imageInHead" src={challenger}
                     style={location.pathname === "/" ? {"pointer-events": "none"} : {}} onClick={routeChange}/>
            </div>)
    )
}

export default HeadBar;
