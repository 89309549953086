import {useContext, useEffect, useState} from "react";
import {Link as LinkRouter, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";

import backArrow from "../../images/backArrow.png";
import "./Shop.css"
import {fetchItems} from "../../FetchData";
import ShopDetails from "./ShopDetails";
import trolleyPicture from "../../images/trolleyPicture.png";
import ShopBag from "./ShopBag";
import Dropdown from 'react-dropdown';
import trash from "../../images/delete-24.png";
import ShopInvoice from "./ShopInvoice";
import GifLoader from 'react-gif-loader';
import loading from "../../images/loading.GIF"
import ProgressiveImage from "react-progressive-image";
import ShopThanks from "./ShopThanks";
// import {FixedSizeList} from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer"
// import useInfiniteScroll from 'react-infinite-scroll-hook';
import useWindowDimensions from "../../windowSizeFunction";


const TotalCost = (props) => {
    let history = useHistory()
    const routeChange = () => {
        let path = "/shop/bag"
        history.push(path);
    }
    return (
        <div className="totalCost">
            <img className="trolleyPictureShop" src={trolleyPicture} alt=""
                 onClick={routeChange}/>
            <div className="payWrapper">
                <div className="itemListScroll">
                    {props.chosenItems.map(p =>
                        <div className="shopBagRow"
                             onClick={() => {

                                 props.setShopState(
                                     {
                                         ...props.shopState,
                                         chosenItems: props.chosenItems.filter(elem => elem.item.id !== p.item.id),
                                         // totalCost: props.shopState.totalCost - (Math.floor(p.item.price*(1 - props.shopState.discount.value)*100)/100)*p.count
                                     }
                                 )
                             }}
                        >
                            <p className="chosenItemsListElementShop">{p.item.name} x{p.count}</p>
                            <img src={trash} className="trashImage"/>
                        </div>)}
                </div>
                {/*<p className="totalCostMargin">Total cost: {Math.floor(props.totalCost*(1 - props.shopState.discount.value)*100)/100} zł</p>*/}
                <p className="totalCostMargin">Total
                    cost: {props.totalCost.toFixed(2)} zł {props.shopState.discount.value > 0 ? `(-${props.shopState.discount.value * 100}%)` : ""}</p>
                <LinkRouter className="payNow" to={"/shop/bag"}>
                    <button className="payNowButton">Order now!</button>
                </LinkRouter>
            </div>
        </div>
    )
}

// const createShopList = (products) => {
//     let len = products.length
//     let lasts = len%2
//     let listOfLists = []
//     for (let i = 0; i < Math.floor(len / 2); i++) {
//         listOfLists.push(Array(products[i*2], products[i*2 + 1]))
//     }
//     if (lasts > 0) {
//         listOfLists.push(Array(products[len - 1]))
//     }
//     return (
//         (len>0)? <div className="shopList">
//             {listOfLists.map((elems) =>
//                 (<div className="shopRow">
//                     {elems.map((elem) => (<ShopMiniatureElement id={elem.id} filteredItems={products}/>))}
//                 </div>))}
//         </div> : null
//     )
// }

const createShopList = (shopState, setShopState) => {
    return (shopState.filteredItems.map((elem) => <ShopMiniatureElement id={elem.id}
                                                                        item={elem}
                                                                        filteredItems={shopState.filteredItems}
                                                                        chosenItems={shopState.chosenItems}
                                                                        addToCart={(data) => setShopState({...shopState, ...data})}/>))
}


const FilterCollection = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [value]
    const shopState = props.shopState
    const setSelectedOptions = props.setSelectedOptions
    const uniq = [...new Set(shopState.items.map((elem) => elem["category"])), "All"].sort()
    return (
        <Dropdown className="dropDown" options={uniq} onChange={(event) => {
            setSelectedOptions(event.value)
        }} placeholder={props.actualValue}
            // value={props.actualValue}
        />
        // <option className="filterList">
        //     {uniq.map((elem) =>
        //         <p onClick={() => setShopState({...shopState, filteredItems: shopState.items.filter((item) => (item.collection === elem))})}>
        //             {elem}
        //         </p>)
        //     }
        // </option>
    )
}
const FilterLanguage = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const shopState = props.shopState
    const setSelectedOptions = props.setSelectedOptions
    const uniq = [...new Set(shopState.items.map((elem) => elem["languageLevel"])), "All"].sort()
    return (
        <Dropdown className="dropDown" options={uniq} onChange={(event) => {
            setSelectedOptions(event.value)
        }} placeholder={props.actualValue}/>
        // <option className="filterList">
        //     {uniq.map((elem) =>
        //         <p onClick={() => setShopState({...shopState, filteredItems: shopState.items.filter((item) => (item.languageLevel === elem))})}>
        //             {elem}
        //         </p>)
        //     }
        // </option>
    )
}

const FilterPrice = (props) => {
    const shopState = props.shopState
    const selectedOptions = props.selectedOptions
    const setSelectedOptions = props.setSelectedOptions

    const [values, setValues] = useState(selectedOptions.price)
    const [wrongPrice, setWrongPrice] = useState(false)

    const handleChange = () => {
        if (values.from <= values.to) {
            setWrongPrice(false)
            setSelectedOptions(values)
        } else {
            setWrongPrice(true)
        }
    }

    useEffect(() => {
        handleChange()
    }, [values])
    return (
        <div className="filterPrice">
            <div className="filterPriceRow">
                <input type="text" placeholder={values.from}
                       onChange={(event) => setValues({from: Number(event.target.value), to: values.to})}/>
                <p className="priceLine">-</p>
                <input type="text" placeholder={values.to}
                       onChange={(event) => setValues({from: values.from, to: Number(event.target.value)})}/>
            </div>
            {wrongPrice ? <p className="wrongPrice">WRONG PRICE RANGE</p> : null}
        </div>
    )
}


const Filter = (props) => {
    const shopState = props.shopState
    const setShopState = props.setShopState
    const parsedFilters = JSON.parse(sessionStorage.getItem("filters")) || {
        category: "All",
        languageLevel: "All",
        price: {
            from: 0,
            to: 999
        }
    }
    const [selectedOptions, setSelectedOptions] = useState(parsedFilters)

    const filterItems = (item) => {
        if (selectedOptions.category === "All" && selectedOptions.languageLevel === "All") {
            return (
                item.price >= selectedOptions.price.from && item.price <= selectedOptions.price.to
            )
        }
        if (selectedOptions.category === "All") {
            return (
                item.price >= selectedOptions.price.from &&
                item.price <= selectedOptions.price.to &&
                item.languageLevel === selectedOptions.languageLevel

            )
        }
        if (selectedOptions.languageLevel === "All") {
            return (
                item.price >= selectedOptions.price.from &&
                item.price <= selectedOptions.price.to &&
                item.category === selectedOptions.category

            )
        }
        return (
            item.price >= selectedOptions.price.from &&
            item.price <= selectedOptions.price.to &&
            item.category === selectedOptions.category &&
            item.languageLevel === selectedOptions.languageLevel
        )
    }
    useEffect(() => {
        setShopState({...shopState, filteredItems: shopState.items.filter(filterItems)})
        sessionStorage.setItem("filters", JSON.stringify(selectedOptions))
    }, [selectedOptions])

    return (
        <div className="filtersWrapper">
            <div className="filters">
                <div className="filterElement">
                    <label className="labelStyle" htmlFor="collections">COLLECTIONS</label>
                    <FilterCollection shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions(options === "Free" ? {
                        ...selectedOptions,
                        category: options,
                        price: {from: 0, to: 999}
                    }: {
                        ...selectedOptions,
                        category: options,
                    }))}
                                      actualValue={selectedOptions.category}
                    />
                </div>
                <div className="filterElement">
                    <label className="labelStyle" htmlFor="language">LANGUAGE LEVEL</label>
                    <FilterLanguage shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions({
                        ...selectedOptions,
                        languageLevel: options
                    }))}
                                    actualValue={selectedOptions.languageLevel}
                    />
                </div>
                <div className="filterElement">
                    <label className="labelStyle" htmlFor="price">PRICE</label>
                    <FilterPrice shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions({
                        ...selectedOptions,
                        price: options
                    }))}
                                 selectedOptions={selectedOptions}
                    />
                </div>
            </div>
        </div>
    )

}

const FilterOneLiner = (props) => {
    const shopState = props.shopState
    const setShopState = props.setShopState
    const parsedFilters = JSON.parse(sessionStorage.getItem("filters")) || {
        category: "All",
        languageLevel: "All",
        price: {
            from: 0,
            to: 999
        }
    }
    const [selectedOptions, setSelectedOptions] = useState(parsedFilters)

    const filterItems = (item) => {
        if (selectedOptions.category === "All" && selectedOptions.languageLevel === "All") {
            return (
                item.price >= selectedOptions.price.from && item.price <= selectedOptions.price.to
            )
        }
        if (selectedOptions.category === "All") {
            return (
                item.price >= selectedOptions.price.from &&
                item.price <= selectedOptions.price.to &&
                item.languageLevel === selectedOptions.languageLevel

            )
        }
        if (selectedOptions.languageLevel === "All") {
            return (
                item.price >= selectedOptions.price.from &&
                item.price <= selectedOptions.price.to &&
                item.category === selectedOptions.category

            )
        }
        return (
            item.price >= selectedOptions.price.from &&
            item.price <= selectedOptions.price.to &&
            item.category === selectedOptions.category &&
            item.languageLevel === selectedOptions.languageLevel
        )
    }
    useEffect(() => {
        setShopState({...shopState, filteredItems: shopState.items.filter(filterItems)})
        sessionStorage.setItem("filters", JSON.stringify(selectedOptions))
    }, [selectedOptions])
    return (
        <div className="filtersWrapperMobile">
            <div className="filtersMobile">
                <div className="filterElementMobile">
                    <label className="labelStyle" htmlFor="collections">COLLECTIONS</label>
                    <FilterCollection shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions(options === "Free" ? {
                        ...selectedOptions,
                        category: options,
                        price: {from: 0, to: 999}
                    }: {
                        ...selectedOptions,
                        category: options,
                    }))}
                                      actualValue={selectedOptions.category}
                    />
                </div>
                <div className="filterElementMobile">
                    <label className="labelStyle" htmlFor="language">LANGUAGE LEVEL</label>
                    <FilterLanguage shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions({
                        ...selectedOptions,
                        languageLevel: options
                    }))}
                                    actualValue={selectedOptions.languageLevel}/>
                </div>
                <div className="filterElementMobile">
                    <label className="labelStyle" htmlFor="price">PRICE</label>
                    <FilterPrice shopState={shopState} setSelectedOptions={(options) => (setSelectedOptions({
                        ...selectedOptions,
                        price: options
                    }))}
                                 selectedOptions={selectedOptions}/>
                </div>
            </div>
        </div>
    )
}

const ShopMiniatureElement = (props) => {
    const {id, item} = props
    return (
        <div className="shopItemLinkWrapper">
            <div className="shopItem">
                {/*<ProgressiveImage src={`${item.image[0].url}`} placeholder="tiny-image.jpg">*/}
                {/*    {(src, loading) =><img className="shopItemImage" style={{ opacity: loading ? 0.5 : 1 }} src={src}/>}*/}
                {/*</ProgressiveImage>*/}
                <LinkRouter className="shopItemImageContainer" to={`/shop/${id}`}>
                    <img className="shopItemImage" src={item.image[0].url} alt=''/>
                </LinkRouter>
                <LinkRouter className="nameElement" to={`/shop/${id}`}>
                    <p className="nameSize">{item.name}</p>
                    <p className="subjectSize">{item.category}</p>
                </LinkRouter>
                <div className="price">
                    <p className="priceText">{item.price} zł</p>
                </div>
                {/*<button className="addToCartButton" onClick={() => {*/}
                {/*    if ((props.chosenItems.map(p => Number(p.item.id))).includes(Number(id))) {*/}
                {/*        const existingElementCount = props.chosenItems.find(p => p.item.id === Number(id)).count*/}
                {/*        const chosenItems = props.chosenItems.filter(p => p.item.id !== Number(props.item.id))*/}
                {/*        return props.addToCart(*/}
                {/*            {*/}
                {/*                // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),*/}
                {/*                'chosenItems': [*/}
                {/*                    ...chosenItems,*/}
                {/*                    {"item": item, "count": existingElementCount + 1}*/}
                {/*                ]*/}
                {/*            }*/}
                {/*        )*/}
                {/*    } else {*/}
                {/*        return props.addToCart(*/}
                {/*            {*/}
                {/*                // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),*/}
                {/*                'chosenItems': [*/}
                {/*                    ...props.chosenItems,*/}
                {/*                    {"item": item, "count": 1}*/}
                {/*                ]*/}
                {/*            }*/}
                {/*        )*/}
                {/*    }*/}
                {/*}}>*/}
                {/*    <p className="addToCartButtonText">Add</p>*/}
                {/*</button>*/}
            </div>
        </div>
    )
}


const Shop = (props) => {
    const parsedTotalCost = Number(localStorage.getItem("totalCost") || 0)
    const parsedChosenItems = JSON.parse(localStorage.getItem("chosenItems")) || []
    const parsedDiscount = JSON.parse(localStorage.getItem("discount")) || {code: "", value: 0}
    // const parsedItems = JSON.parse(localStorage.getItem("items")) || []
    let location = useLocation()
    const history = useHistory()

    const [shopState, setShopState] = useState({
        items: [],
        filteredItems: [],
        totalCost: parsedTotalCost,
        chosenItems: parsedChosenItems,
        discount: parsedDiscount,
    })
    useEffect(() => {
        if (shopState.items.length === 0) {
            fetchItems().then((data) => {
                    setShopState({...shopState, items: data, filteredItems: data})
                    localStorage.setItem("totalCost", shopState.totalCost)
                    localStorage.setItem("chosenItems", JSON.stringify(shopState.chosenItems))
                }
            )
        }
    }, [])

    useEffect(() => {
        if (shopState.items.length > 0) {
            localStorage.setItem("totalCost", shopState.totalCost)
            localStorage.setItem("chosenItems", JSON.stringify(shopState.chosenItems))
            localStorage.setItem("discount", JSON.stringify(shopState.discount))
        }

    }, [shopState.filteredItems, shopState.totalCost, shopState.discount])

    useEffect(() => {
        //setting data for head shopping list
        let cost = 0
        const len = shopState.chosenItems.length
        for (let i = 0; i < len; i++) {
            cost += ((Math.floor(shopState.chosenItems[i].item.price * (100 - shopState.discount.value * 100)) / 100) * shopState.chosenItems[i].count)
        }
        setShopState({...shopState, "totalCost": cost})
        props.setShoppingData({"chosenItems": shopState.chosenItems, "totalCost": cost, "discount": shopState.discount})
    }, [shopState.chosenItems, shopState.discount])
    return (
        <Switch location={location}>
            <Route path="/shop/thanks">
                <ShopThanks shopState={shopState}
                            setShopState={setShopState}/>
            </Route>
            <Route path="/shop/invoice">
                <ShopInvoice chosenItems={shopState.chosenItems}
                             totalCost={shopState.totalCost}
                             discount={shopState.discount}
                             shopState={shopState}
                             setShopState={(data) => setShopState({...shopState, ...data})}
                />
            </Route>
            <Route path="/shop/bag">
                <ShopBag shopState={shopState}
                         setShopState={setShopState}/>
            </Route>
            <Route path="/shop/:id">
                <ShopDetails shopState={shopState} setShopState={(data) => setShopState({...shopState, ...data})}/>
            </Route>
            <Route path="/shop">
                <div className="shopStyle shopBackground">
                    <div className="shopWrapper">
                        {useWindowDimensions().width > 1500 ? (<div className="manageColumn">
                                <Filter shopState={shopState} setShopState={(state) => setShopState({...state})}/>
                                <TotalCost totalCost={shopState.totalCost} chosenItems={shopState.chosenItems}
                                           shopState={shopState}
                                           setShopState={setShopState}/>
                            </div>)
                            :
                            <FilterOneLiner shopState={shopState} setShopState={(state) => setShopState({...state})}/>}
                        <div className="productsColumn">
                            {
                                createShopList(shopState, setShopState)
                            }
                        </div>
                    </div>
                    <img src={backArrow} className="BackButton" onClick={() => history.push("/")}/>
                </div>
            </Route>
        </Switch>
    )
}

export default Shop
