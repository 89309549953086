import "./ShopThanks.css";
import shoppingThanks from "../../images/shoppingThanks.png";
import {useEffect} from "react";
import backArrow from "../../images/backArrow.png";

const {useHistory} = require("react-router-dom");
const ShopThanks = (props) => {
    let history = useHistory();
    useEffect(() => {
        // props.setShopState({...props.shopState, chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
        // localStorage.setItem("totalCost", 0)
        // localStorage.setItem("chosenItems", JSON.stringify([]))
        // localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
    },[])
    return (
        <div className="shopThanksStyle thanksBackground">
            <div className="shopThanksWrapper">
                <img className="shopThanksImage" src={shoppingThanks} alt="" />
            </div>
            <img src={backArrow} className="BackButton" onClick={() => history.push("/shop")} />
        </div>
    )
}

export default ShopThanks;
