// const fetchedData = async () => {
//     const response = await fetch('http://localhost:1337/items')
//     const data = await response.json()
//     return {
//         items: data,
//         filteredItems: data,
//         totalCost: 0,
//         chosenItems: []
//     }
// }

export const fetchItems = async () => {
    // const response = await fetch('http://localhost:1337/items')
    const response = await fetch('https://backend-68zlq.ondigitalocean.app/items')
    const data = await response.json()
    return data
}

export const fetchPosts = async () => {
    // const response = await fetch('http://localhost:1337/items')
    const response = await fetch('https://backend-68zlq.ondigitalocean.app/posts')
    const data = await response.json()
    return data
}

export const fetchItem = async (id) => {
    // const response = await fetch('http://localhost:1337/items')
    const response = await fetch('https://backend-68zlq.ondigitalocean.app/items/' + id)
    const data = await response.json()
    return data
}

export const fetchPost = async (id) => {
    // const response = await fetch('http://localhost:1337/items')
    const response = await fetch('https://backend-68zlq.ondigitalocean.app/posts/' + id)
    const data = await response.json()
    return data
}


