import {Link as LinkRouter, useHistory, useLocation, useParams} from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import "./ShopDetails.css"
import {useEffect, useMemo, useRef, useState} from "react";
import trolleyPicture from "../../images/trolleyPicture.png"
import trash from "../../images/delete-24.png"
import {fetchItem} from "../../FetchData";
import loading from "../../images/loading.GIF";
import backArrow from "../../images/backArrow.png";
import * as Scroll from 'react-scroll';
import axios from "axios";


const ChosenItemsList = (props) => {
    let history = useHistory()
    const routeChange=()=> {
        let path = "/shop/bag"
        history.push(path);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {}, [props.chosenItems, props.discount])
    }
    return (
        <div className="chosenItemsList">
            <LinkRouter className="payNow" to={"/shop/bag"}>
                <img className="shopBagImageStyle" src={trolleyPicture} alt="" />
            </LinkRouter>
            <div className="payWrapper">
                <div className="payWrapperItemsList">
                    {props.chosenItems.map(p =>
                        (
                            <div className="bagRow"
                            onClick={() => {
                                props.setShopState(
                                    {...props.shopState,
                                        chosenItems: props.chosenItems.filter(elem => elem.item.id !== p.item.id),
                                        // totalCost: props.shopState.totalCost - (Math.floor(p.item.price*(1 - props.shopState.discount.value)*100)/100)*p.count
                                    }
                                )
                            }}
                            >
                                <p
                                    className="chosenItemsListElement"
                                >
                                    {p.item.name} x{p.count}
                                </p>
                                <img src={trash} className="trashImage"/>
                            </div>
                        )
                    )
                    }
                </div>
                <p className="totalCostMargin">Total cost: {props.totalCost.toFixed(2)} zł {props.shopState.discount.value > 0 ? `(-${props.shopState.discount.value*100}%)`: ""}</p>
                <LinkRouter className="payNow" to={"/shop/bag"}>
                    <button className="payNowButtonDetails">Order now!</button>
                </LinkRouter>
            </div>
        </div>
    )
}
const Description = (props) => {
    return (
        <div className="textDescription">
            <p className="itemName">{props.name}    {props.price}zł</p>
            <div className="squareRow">
                {/*<p className="square borderColorPrice">{props.price}zł</p>*/}
                <p className="square borderColorCat">{props.category}</p>
                <p className="square borderColorCat">{props.langLvl}</p>
                <p className="square borderColorType">{props.type}</p>
            </div>
            <p className="itemDescription">{props.description}</p>
        </div>
    )
}

const Counter = (props) => {
    const [counter, setCounter] = useState(1)
    return (
        <div className="elementCounterAdder">
            <div className="elementCounter">
                <button className="countButton"
                        onClick={
                            () => {
                                if (counter !== 0) {
                                    return setCounter(counter - 1)
                                }
                            }}>
                    <p>-</p>
                </button>
                <div className="countNumber">
                    <p>{counter}</p>
                </div>
                <button className="countButton" onClick={() => setCounter(counter + 1)}>
                    <p>+</p>
                </button>
            </div>
            <button className="elementAdder" onClick={() => {
                if(props.item.price === 0) {
                    axios.post('https://backend-68zlq.ondigitalocean.app/payment/free/',
                        {id: props.item.id })
                        .then(response => {
                            window.location.replace(response.data.data.link);
                        })
                } else {
                    if(counter > 0){
                        if ((props.chosenItems.map(p => Number(p.item.id))).includes(Number(props.item.id))) {
                            const existingElementCount = props.chosenItems.find(p => p.item.id === Number(props.item.id)).count
                            const chosenItems = props.chosenItems.filter(p => p.item.id !== Number(props.item.id))
                            return props.addToCart(
                                {
                                    // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),
                                    'chosenItems': [
                                        ...chosenItems,
                                        {"item": props.item, "count": existingElementCount + counter}
                                    ]
                                }
                            )
                        } else {
                            return props.addToCart(
                                {
                                    // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),
                                    'chosenItems': [
                                        ...props.chosenItems,
                                        {"item": props.item, "count": counter}
                                    ]
                                }
                            )
                        }
                    }
                }
            }
            }>
                {props.item.price > 0? <p>Add to cart!</p>: <p>Click to download!</p>}
            </button>
        </div>
    )
}

const ElementDetails = (props) => {
    return (
        <div className="elementDetails">
            <div className="imageComponent">
                <ImageGallery
                    showThumbnails={false}
                    renderPlayPauseButton={() => {
                    }}
                    // renderFullscreenButton={() => {
                    // }}
                    items={props.pictures}/>
            </div>
            <div className="elementDescription">
                <Description description={props.item.description} name={props.item.name} category={props.item.category}
                             price={props.item.price}
                             type={props.item.type}  langLvl={props.item.languageLevel}
                />
                <Counter
                    addToCart={(data) => props.setShopState(data)}
                    discount={props.discount}
                    item={props.item}
                    totalCost={props.totalCost}
                    alreadyChosen={props.alreadyChosen}
                    chosenItems={props.chosenItems}
                />
            </div>

        </div>
    )
}

const ShopMiniatureElement = (props) => {
    const {id, item, setChangedId} = props
    // console.log("id", id)
    const history = useHistory()
    const location = useLocation()
    let scroll = Scroll.animateScroll
    let scrollSpy = Scroll.scrollSpy

    return (
        <div className="shopItemLinkWrapper">
            <div className="shopItem">
                {/*<ProgressiveImage src={`${item.image[0].url}`} placeholder="tiny-image.jpg">*/}
                {/*    {(src, loading) =><img className="shopItemImage" style={{ opacity: loading ? 0.5 : 1 }} src={src}/>}*/}
                {/*</ProgressiveImage>*/}
                <LinkRouter className="shopItemImageContainer" onClick={() => {
                    history.push(`/shop/${id}`)

                }}>
                    <img className="shopItemImage" src={item.image[0].url} alt=''/>
                </LinkRouter>
                <LinkRouter className="nameElement" onClick={() => {
                    history.push(`/shop/${id}`)
                    // window.scrollTo(0, 0)
                    // scrollSpy.update()
                }}>
                    <p className="nameSize">{item.name}</p>
                    <p className="subjectSize">{item.category}</p>
                </LinkRouter>
                <div className="price">
                    <p className="priceText">{item.price} zł</p>
                </div>
                {/*<button className="addToCartButton" onClick={() => {*/}
                {/*    if ((props.chosenItems.map(p => Number(p.item.id))).includes(Number(id))) {*/}
                {/*        const existingElementCount = props.chosenItems.find(p => p.item.id === Number(id)).count*/}
                {/*        const chosenItems = props.chosenItems.filter(p => p.item.id !== Number(props.item.id))*/}
                {/*        return props.addToCart(*/}
                {/*            {*/}
                {/*                // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),*/}
                {/*                'chosenItems': [*/}
                {/*                    ...chosenItems,*/}
                {/*                    {"item": item, "count": existingElementCount + 1}*/}
                {/*                ]*/}
                {/*            }*/}
                {/*        )*/}
                {/*    } else {*/}
                {/*        return props.addToCart(*/}
                {/*            {*/}
                {/*                // 'totalCost': props.totalCost + counter * (Math.floor(props.item.price*(1 - props.discount.value)*100)/100),*/}
                {/*                'chosenItems': [*/}
                {/*                    ...props.chosenItems,*/}
                {/*                    {"item": item, "count": 1}*/}
                {/*                ]*/}
                {/*            }*/}
                {/*        )*/}
                {/*    }*/}
                {/*}}>*/}
                {/*    <p className="addToCartButtonText">Add</p>*/}
                {/*</button>*/}
            </div>
        </div>
    )
}

const AdditionalList = (props) => {
    const {shopState, setShopState, setChangedId, id} = props;
    const len = shopState.items.length
    const num = useMemo(() => Math.floor(Math.random()*len), [id]);
    const ids = [num%len, (num+1)%len, (num+2)%len];
    const chosen = shopState.items.filter(item => ids.includes(Number(item.id)));
    return (
        <div className="checkAlso">
            {chosen.map((elem) => <ShopMiniatureElement id={elem.id}
                                                        item={elem}
                                                        setChangedId={setChangedId}
                                                        filteredItems={shopState.filteredItems}
                                                        chosenItems={shopState.chosenItems}
                                                        addToCart={(data) => setShopState({...shopState, ...data})}/>)}
        </div>
    )
}

const ShopDetails = (props) => {
    const {id} = useParams()
    console.log(id)
    const setShopState = props.setShopState
    const totalCost = props.shopState.totalCost
    const chosenItems = props.shopState.chosenItems || []
    const discount = props.shopState.discount || {code: "", value: 0}
    const alreadyChosen = chosenItems.filter(p => p.id === Number(id)).length
    const [item, setItem] = useState({})
    const [loaded, setLoad] = useState(false)
    const [pictures, setPictures] = useState({})
    let location = useLocation()
    const history = useHistory()
    const [changedId, setChangedId] = useState(id)

    // const item = items.find(p => p.id === Number(id))
    // const pictures = item.descriptionImages.map(item => ({"original": item.url, "thumbnail": item.url}))

    useEffect(() => {
        fetchItem(id).then((response) =>{
                setItem(response)
                setPictures(response.descriptionImages.map(item => ({"original": `${item.formats.medium.url}`, "thumbnail": `${item.formats.thumbnail.url}`})))
                setLoad(true)
            }
        )
    }, [id])

    useEffect(() => {
    }, [props.shopState.chosenItems, loaded, changedId])
    return (
        loaded? (
            <div className="shopDetailsBackground" style={
                item.background.formats.large !== null ? {"backgroundImage": `url(${item.background.formats.large.url})`} : {"background-color": "#222222"}
            }>
                <div className="shopDetailsStyle">
                    <div className="shopDetailsWrapper">
                        <ElementDetails
                            item={item}
                            pictures={pictures}
                            totalCost={totalCost}
                            chosenItems={chosenItems}
                            alreadyChosen={alreadyChosen}
                            discount={discount}
                            setShopState={(data) => setShopState(data)}/>
                        <ChosenItemsList
                            shopState={props.shopState}
                            setShopState={setShopState}
                            chosenItems={chosenItems}
                            totalCost={totalCost}
                            discount={discount}
                        />
                    </div>
                </div>
                {/*<AdditionalList shopState={props.shopState} setShopState={setShopState} id={id} setChangedId={(id) => setChangedId(id)}/>*/}
                <img src={backArrow} className="BackButton" onClick={() => history.push("/shop")} />
            </div>
        ): <div className="loading" ></div>
    )
}

export default ShopDetails
