import {useHistory, useParams} from "react-router-dom";
import {fetchPost} from "../../FetchData";
import backArrow from "../../images/backArrow.png";

const text = `Polityka Prywatności
Definicję:
    a) Definicje
    a. Administrator – Katarzyna Nowak prowadząca działalność gospodarczą pod firmą PERFECTUS Katarzyna Nowak wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, ul. Piastowska 11/54, 26-600, Radom NIP 7962761579
    a. Dane osobowe ­– informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej;
    b. Polityka Prywatności – niniejsza polityka prywatności;
    c. Regulamin – regulamin Sklepu Internetowego;
    d. RODO – Rozporządzenie Parlamentu Europejskiego i RADY (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);
    e. Sklep Internetowy – sklep internetowy Challenger by Kate prowadzony pod adresem https://www.challengerbykate.pl/;
    f. Użytkownik – każda osoba fizyczna odwiedzająca witrynę internetową i korzystająca z jednej lub kilku usług/funkcjonalności oferowanych w Witrynie Internetowej.
Administrator:
    a) Administrator 
    b. Administratorem Danych Osobowych Użytkownika jest Katarzyna Nowak prowadząca działalność gospodarczą pod firmą PERFECTUS Katarzyna Nowak wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, ul. Piastowska 11/54, 26-600, Radom NIP 7962761579
Kontakt z Administratorem:
    b) Kontakt z administratorem
    a. Z Administratorem możesz skontaktować się w formie pisemnej, pisząc na adres: Piastowska 11/54, 26-600, Radom, drogą mailową na adres challenger.by.kate@gmail.com lub przez stronę Facebook’ową pod nazwą Challenger by Kate (@CHALLENGERbyKate). W sprawach dotyczących zamówień złożonych w Sklepie Internetowym Challenger by Kate, należy kontaktować się bezpośrednio pisząc na adres: challenger.by.kate@gmail.com lub przez formularz kontaktowy na stronie.
    c) Cele wykorzystania Twoich danych osobowych i ich podstawy prawne
        a. Przeglądanie i korzystanie ze sklepu internetowego
    1. Dane Osobowe osoby przeglądającej lub korzystającej ze Sklepu Internetowego (np. adres IP lub informacje gromadzone w plikach cookies lub podobnych) będą przetwarzane przez Administratora w następujących celach:
    I. świadczenia przez Administratora usługi drogą elektroniczną polegającej na udostępnianiu zawartości Sklepu Internetowego osobom go przeglądającym, zapamiętywania zawartości koszyka zakupowego, udostępniania formularza kontaktowego – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wykonania umowy (art. 6 ust. 1 lit. b RODO);
    II. analitycznych i statystycznych – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie zachowań i preferencji osób korzystających ze Sklepu Internetowego celem poprawy jakości udostępnianych funkcjonalności i świadczonych usług (art. 6 ust. 1 lit. f RODO);
    III. ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają ochronie jego praw (art. 6 ust. 1 lit. f RODO)

        b. Składanie i realizacja zamówień 
    1. Użytkownicy składający zamówienie w Sklepie Internetowym proszeni są o podanie Danych Osobowych niezbędnych do złożenia i realizacji zamówienia polegającego na zakupie towaru lub usługi. Podanie Danych Osobowych oznaczonych jako obowiązkowe jest dobrowolne, ale niezbędne do założenia zamówienia i jego realizacji. Niepodanie tych Danych Osobowych skutkuje brakiem możliwości złożenia zamówienia.
    2. Dane Osobowe Użytkownika składającego zamówienie będą przetwarzane przez Administratora w następujących celach:
    I. przetworzenia i realizacji złożonego zamówienia – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wykonania umowy (art. 6 ust. 1 lit. b RODO);
    II. wykonania przez Administratora ciążących na nim obowiązków prawnych, w szczególności obowiązków wynikających z przepisów w zakresie podatków i rachunkowości – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO);
    III. analitycznych i statystycznych – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie zachowań i preferencji Użytkowników składających zamówienie w Sklepie Internetowym celem poprawy jakości udostępnianych funkcjonalności i świadczonych usług (art. 6 ust. 1 lit. f RODO);
    d) ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają ochronie jego praw (art. 6 ust. 1 lit. f RODO);

        a. Kontakt z administratorem
        1. Użytkownicy chcący skorzystać z formularza kontaktowego w celu skontaktowania się z Administratorem proszeni są o podanie Danych Osobowych niezbędnych do przetworzenia zapytania, jego obsługi, identyfikacji Użytkownika oraz skontaktowania się z nim. Podanie Danych Osobowych oznaczonych jako obowiązkowe jest dobrowolne, ale niezbędne w celu obsługi zapytania, a niepodanie tych Danych Osobowych skutkuje brakiem możliwości przesłania zapytania Administratorowi.
        2. Dane Osobowe Użytkownika przesyłającego zapytanie będą przetwarzane przez Administratora w następujących celach:
    I. przetworzenie zapytania, jego obsługa, identyfikacja Użytkownika oraz skontaktowania się z nim – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wykonania umowy (art. 6 ust. 1 lit. b RODO);
    II. analitycznych i statystycznych – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie zachowań i preferencji Użytkowników oraz treści ich zapytań celem poprawy jakości udostępnianych funkcjonalności i świadczonych usług (art. 6 ust. 1 lit. f RODO);
    III. ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają ochronie jego praw (art. 6 ust. 1 lit. f RODO).

        b. Odstąpienie od umowy i złożenie reklamacji
    1. Użytkownicy składający zamówienie Sklepie Internetowym proszeni są o podanie Danych Osobowych niezbędnych do złożenia reklamacji, wystawienia faktury lub oświadczenia o odstąpieniu od umowy zawartej na odległość. Podanie Danych Osobowych oznaczonych jako obowiązkowe jest dobrowolne, ale niezbędne cele do przetworzenia reklamacji/oświadczenia o odstąpieniu od umowy, a niepodanie tych Danych Osobowych skutkuje brakiem możliwości realizacji praw Użytkownika.
    2. Dane Osobowe Użytkownika składającego zamówienie będą przetwarzane przez Administratora w następujących celach:
    I. przetworzenia i rozpatrzenia złożonej reklamacji i oświadczenia o odstąpieniu od umowy – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO);
    II. wykonania przez Administratora ciążących na nim obowiązków prawnych, w szczególności obowiązków wynikających z przepisów w zakresie podatków i rachunkowości – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO);
    III. analitycznych i statystycznych – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie złożonych reklamacji i oświadczeń o odstąpieniu celem poprawy jakości udostępnianych funkcjonalności, świadczonych usług oraz sprzedawanych produktów (art. 6 ust. 1 lit. f RODO);
    IV. ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają ochronie jego praw (art. 6 ust. 1 lit. f RODO);

        c. Korzystanie z mediów społecznościowych
    1. W przypadku gdy Użytkownicy odwiedzają portale społecznościowe prowadzone przez Administratora (Facebook, Instagram, Pinterest, LinkedIn) Administrator przetwarza Dane Osobowe Użytkowników w związku z prowadzeniem profilu.
    2. Dane Osobowe Użytkownika korzystającego z portali społecznościowych Administratora będą przetwarzane przez Administratora w następujących celach:
        I. prowadzenia profili w mediach społecznościowych tj. zamieszczania tam treści oraz promocji swoich usług i towarów – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie złożonych reklamacji i oświadczeń o odstąpieniu celem poprawy jakości udostępnianych funkcjonalności, świadczonych usług oraz sprzedawanych produktów (art. 6 ust. 1 lit. f RODO);
        II. przetworzenie treści otrzymanej wiadomości, jej obsługa, identyfikacja Użytkownika oraz kontakt z nim – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na utrzymywaniu kontaktu z Użytkownikami, promocji produktów i usług także marki własnej (art. 6 ust. 1 lit. f RODO);
        III. analitycznych i statystycznych – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają na analizie złożonych reklamacji i oświadczeń o odstąpieniu celem poprawy jakości udostępnianych funkcjonalności, świadczonych usług oraz sprzedawanych produktów  (art. 6 ust. 1 lit. f RODO);
        IV. ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami – podstawa prawna przetwarzania – przetwarzanie Danych Osobowych jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora, które polegają ochronie jego praw (art. 6 ust. 1 lit. f RODO);
    e) Pliki cookies i inne technologie śledzące 
    a. Pliki cookies to małe informacje tekstowe, przechowywane na urządzeniu końcowym (urządzenie, na którym przeglądany jest Sklep Internetowy np. komputer, smartphone, tablet) Użytkownika, które mogą być odczytywane przez system teleinformatyczny Administratora (cookies własne) lub system teleinformatyczny podmiotów trzecich (cookies podmiotów trzecich).
    b. Pliki cookies są wykorzystywane za zgodą Użytkownika. Zgoda zostaje wyrażona poprzez odpowiednie ustawienia oprogramowania, w szczególności przeglądarki internetowej, zainstalowanej przez Użytkownika na urządzeniu końcowym (urządzenie, na którym przeglądany jest Sklep Internetowy np. komputer, smartphone, tablet).
    c. W Sklepie Internetowym są wykorzystywane następujące rodzaje plików cookies:
    1. pliki cookies z danymi wprowadzanymi przez Użytkownika (identyfikator sesji) na czas trwania sesji (ang. user input cookies);
    2. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania na czas trwania sesji (ang. authentication cookies);
    3. pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania (ang. user centric security cookies);
    4. sesyjne pliki cookies odtwarzaczy multimedialnych (np. pliki cookies odtwarzacza flash), na czas trwania sesji (ang. multimedia player session cookies);
    5. trwałe pliki cookies służące do personalizacji interfejsu Użytkownika na czas trwania sesji lub nieco dłużej (ang. user interface customization cookies),
    6. pliki cookies służące do zapamiętywania zawartości koszyka na czas trwania sesji (ang. shopping cart cookies);
    7. pliki cookies mające na celu monitorowania ruchu w Sklepie Internetowym, tj. analityki danych, w tym cookies Google Analytics (są to pliki wykorzystywane przez spółkę Google celem bieżącej analizy zachowań użytkowników korzystających ze Sklepu Internetowego celem tworzenia statystyk i raportów dotyczących funkcjonowania Sklepu Internetowego). Google nie wykorzystuje zebranych danych do identyfikacji Użytkownika ani nie łączy tych informacji w celu umożliwienia identyfikacji. Szczegółowe informacje o zakresie i zasadach zbierania danych w związku z tą usługą można znaleźć pod linkiem: https://www.google.com/intl/pl/policies/privacy/partners.
    d. Administrator oraz jego zaufani partnerzy mogą wykorzystywać pliki cookies także w celach marketingowych m.in. celem kierowania do Użytkowników reklamy behawioralnej. W tym celu Administrator oraz zaufani partnerzy przechowują informacje lub uzyskują dostęp do informacji już przechowywanych w telekomunikacyjnym urządzeniu końcowym Użytkownika (komputer, telefon, tablet itp.). Wykorzystanie plików cookies oraz zebranych za ich pośrednictwem Danych Osobowych w celach marketingowych, w szczególności w zakresie promowania usług i towarów podmiotów trzecich, wymaga uzyskania zgody Użytkownika. Zgoda ta może być w każdym momencie wycofana.
    e. Poniżej znajdują się linki w których zawarto informacje dotyczące plików cookies w ustawieniach najpopularniejszych przeglądarek internetowych:
        1. Google chrome: https://support.google.com/chrome/answer/95647?hl=pl
        2. Mozilla Firefox: https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectlocale=pl&redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek
        3. Internet Explorer:  https://support.microsoft.com/pl-pl/hub/4338813/windows-help?os=windows-10
        4. Opera:  https://help.opera.com/pl/latest/web-preferences/#cookies
        5. Safari:  https://support.apple.com/pl-pl/guide/safari/sfri11471/mac

    f) Odbiorcy danych osobowych 
    1. W związku z przetwarzaniem Danych Osobowych w celach wskazanych w pkt d Dane Osobowe będą ujawniane zewnętrznym podmiotom, w tym w szczególności podmiotom świadczącym na rzecz Administratora usługi hostingowe, informatyczne, księgowe, prawne, marketingowe, a także bankom i operatorom płatności.
    2. Administrator zastrzega sobie prawo ujawnienia wybranych informacji dotyczących Użytkownika właściwym organom bądź osobom trzecim, które zgłoszą żądanie udzielenia takich informacji, opierając się na odpowiedniej podstawie prawnej oraz zgodnie z przepisami obowiązującego prawa.

    g) Okres przetwarzania danych osobowych 
    1. Okres przetwarzania Danych Osobowych Użytkownika jest zależy od usługi lub umowy w związku z którą Administrator przetwarza jego Dane Osobowe, a także celu tego przetwarzania. Dane Osobowe są przetwarzane przez czas świadczenia usługi, realizacji umowy, wycofania wyrażonej zgody bądź też złożenia skutecznego sprzeciwu w zakresie przetwarzania Danych Osobowych Użytkownika w zakresie przetwarzania jego Danych Osobowych w przypadku, gdy podstawą prawną ich przetwarzania jest uzasadniony interes Administratora. Dane Osobowe mogą być przetwarzane przez dłuższy czas w przypadku, gdy wymagają tego przepisy prawa.
    2. Okres przetwarzania Danych Osobowych o którym mowa w podpunkcie a. zostanie wydłużony w przypadku, gdy dalsze przetwarzania Danych Osobowych Użytkownika będzie niezbędne do ustalenia i dochodzenia ewentualnych roszczeń przez Administratora. Po upływie tego czasu Dane Osobowe zostaną usunięte bądź zanonimizowane.

    h) Prawa związane z przetwarzaniem danych osobowych 
    1. Użytkownik ma następujące prawa związane z przetwarzaniem jego danych osobowych przez Administratora:
    a. prawo dostępu do danych osobowych,
    b. prawo do sprostowania danych osobowych,
    c. prawo do usunięcia danych osobowych,
    d. prawo do ograniczenia przetwarzania danych osobowych,
    e. prawo do wniesienia sprzeciwu co do przetwarzania danych osobowych,
    f. prawo do przenoszenia danych,
    g. prawo do wniesienia skargi do organu nadzorczego (Prezes Urzędu Ochrony Danych Osobowych),
    2. W przypadku gdy Dane Osobowe Użytkownika są przetwarzane na podstawie uzyskanej od niego zgody, Użytkownik może ją wycofać w każdym czasie kontaktując się z Administratorem lub korzystając z funkcjonalności dostępnych w Sklepie Internetowym. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania dokonanego na podstawie zgody przed jej wycofaniem.
    3. Użytkownik ma prawo do wyrażenia sprzeciwu względem przetwarzania jego Danych Osobowych dla celów marketingowych, jeżeli przetwarzanie to odbywa się na podstawie uzasadnionego interesu Administratora. W przypadku przyczyn związanych ze szczególną sytuacją Użytkownika, uprawnienie to przysługuje mu także w sytuacji gdy podstawą prawną przetwarzania jego Danych Osobowych jest uzasadniony interes Administratora (np. w przypadku realizacji celów analitycznych i statystycznych).

    i) Zmiany polityki prywatności 
    a. Administrator na bieżąco weryfikuje treść Polityki Prywatności i w razie potrzeby aktualizuje jej treść.
    b. Aktualna wersja Polityki Prywatności obowiązuje od dnia 1/05/2020
`

const Privacy = () => {
    let history = useHistory();
    return (
        <div className="termsAndPrivacy">
            <div className="termsAndPrivacyContent">
                <div className="textComponentTermsAndPrivacy">
                    <p>{text}</p>
                </div>
                <img src={backArrow} className="BackButton" onClick={() => history.goBack()}/>
            </div>
        </div>)
}

export default Privacy
