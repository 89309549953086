import {useHistory, useParams} from "react-router-dom";
import {fetchPost} from "../../FetchData";
import backArrow from "../../images/backArrow.png";

const text = `1. POSTANOWIENIA OGÓLNE
1.1. Sklep Internetowy Challenger by Kate działający pod adresem challengerbykate.pl prowadzony jest przez Katarzynę Nowak prowadzącą działalność gospodarczą pod firmą PERFECTUS Katarzyna Nowak wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, Piastowska 11/54, 26-600 Radom, NIP 7962761579z którą można skontaktować się korespondencyjnie lub pisząc na adres: challenger.by.kate@gmail.com
1.2. Niniejszy Regulamin określa w szczególności:
1.2.1. zasady korzystania ze Sklepu Internetowego;
1.2.2. warunki składania Zamówień, dostarczania zamówionych Produktów i Usług oraz dokonywanych płatności;
1.2.3. uprawnienia Konsumenta i Przedsiębiorcy na prawach konsumenta w zakresie możliwości odstąpienia od umowy zawartej na odległość;
1.2.4. zasady składania i rozpatrywania reklamacji.
1.3. Każdy Użytkownik korzystający ze Sklepu Internetowego jest zobowiązany do zapoznania się z treścią Regulaminu i przestrzegania jego postanowień.
2. DEFINICJE
2.1. Ilekroć w treści Regulaminu użyto któregoś z poniżej zdefiniowanych wyrażeń, należy je rozumieć w sposób określony poniżej:
2.1.1. E-book – książka w wersji elektronicznej (cyfrowej). E-book jest zapisany w formacie PDF. E-booki dostępne w Sklepie Internetowym są dostarczane w postaci plików cyfrowych do pobrania na urządzenie Klienta;
2.1.2. Hasło Dostępu — ciąg znaków używany do zabezpieczenia dostępu do Platformy;
2.1.3. Klient– użytkownik Sklepu Internetowego, posiadający status Konsumenta, Przedsiębiorcy na prawach konsumenta lub Przedsiębiorcy;
2.1.4. Konsument– Klient, który jest osobą fizyczną, korzystający ze Sklepu Internetowego, w szczególności zawierający Umowę o świadczenie usług drogą elektroniczną lub Umowę o dostarczenie treści cyfrowych w celu niezwiązanym bezpośrednio z jego działalnością gospodarczą lub zawodową (zgodnie z art. 22(1) Kodeksu Cywilnego);
2.1.5. Materiały Audiowizualne – treści cyfrowe udostępniane przez Sprzedającego w dowolnej formie, w szczególności materiały audiowizualne, tekstowe, graficzne, fotograficzne oraz dźwiękowe, niezależnie od tego, czy stanowią utwory w rozumieniu ustawy z o prawie autorskim i prawach pokrewnych. Klient uzyskuje dostęp do wybranych Materiałów Audiowizualnych w wyniku zawarcia ze Sprzedającym Umowy o świadczenie usług drogą elektroniczną.
2.1.7. Platforma – środowisko programistyczne, za pośrednictwem którego Klient ma możliwość skorzystania z Usług tj. odtwarzania Materiałów Audiowizualnych;
2.1.8. Produkt – produkty dostępne w Sklepie Internetowym tj. E-booki;
2.1.9. Przedsiębiorca– Klient nie będący Konsumentem, będący osobą fizyczną, osobą prawną lub jednostką organizacyjną, której obowiązujące przepisy prawa przyznają zdolność prawną, prowadzący we własnym imieniu działalność gospodarczą lub zawodową;
2.1.10. Przedsiębiorca na prawach konsumenta – osoba fizyczna zawierająca umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.
2.1.11. Regulamin– niniejszy regulamin określający zasady i warunki korzystania ze Sklepu Internetowego;
2.1.12. Sklep Internetowy – platforma teleinformatyczna prowadzona pod adresem trudnasztuka.pl przez Sprzedającego, będąca środkiem do porozumiewania się na odległość, umożliwiająca Klientom zapoznanie się z ofertą, składanie Zamówień oraz zawieranie Umów o świadczenie usług drogą elektroniczną i Umów o dostarczenie treści cyfrowych na zasadach opisanych w Regulaminie;
2.1.13. Sprzedający– Katarzyna Nowak prowadząca działalność gospodarczą pod firmą PERFECTUS Katarzyna Nowak wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, ul. Piastowska 11/54, 26-600, Radom NIP 7962761579
2.1.14. Umowa o dostarczenie treści cyfrowych– umowa sprzedaży Produktów w rozumieniu Kodeksu cywilnego, zawierana przy wykorzystaniu środków porozumiewania się na odległość pomiędzy Sprzedającym a Klientem;
2.1.15. Umowa o świadczenie usług drogą elektroniczną – umowa zawarta pomiędzy Klientem i Sprzedającym w zakresie odpłatnego świadczenia Usługi przez Sprzedającego na rzecz Klienta;
2.1.16. Usługa – usługa świadczona drogą elektroniczną, bez jednoczesnej obecności stron (na odległość), poprzez przekaz danych na indywidualne żądanie Klienta , przesyłanej i otrzymywanej za pomocą urządzeń do elektronicznego przetwarzania, włącznie z kompresją cyfrową, i przechowywania danych, która jest w całości nadawana, odbierana lub transmitowana za pomocą sieci telekomunikacyjnej w rozumieniu ustawy Prawo telekomunikacyjne;
2.1.17. Użytkownik– każda osoba fizyczna korzystająca ze Sklepu Internetowego;
2.1.18. Zamówienie – oświadczenie woli Klienta zmierzające bezpośrednio do zawarcia Umowy o świadczenie usług drogą elektroniczną lub Umowy o dostarczenie treści cyfrowych na zasadach opisanych w Regulaminie.
2.1.19 PayPro SA (PayPro) – Podmiot świadczący Usługę na rzecz Użytkowników, z siedzibą w Poznaniu, przy ulicy Kanclerskiej 15 (60-327), wpisany do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000347935, numerem NIP 7792369887, o kapitale zakładowym wynoszącym 5 476 300,00 zł, w całości opłaconym oraz do rejestru krajowych instytucji płatniczych prowadzonego przez Komisję Nadzoru Finansowego pod numerem UKNF IP24/2014.
2.1.20 Serwis Przelewy24 (Serwis) - internetowy serwis usługowy, pośredniczącym w przekazywaniu płatności pomiędzy Płatnikiem i Akceptantem.

3. WARUNKI I ZASADY KORZYSTANIA ZE SKLEPU INTERNETOWEGO
3.1. Sprzedający prowadzi sprzedaż Produktów i Usług z wykorzystaniem Sklepu Internetowego będącego systemem teleinformatycznym w rozumieniu ustawy o świadczeniu usług drogą elektroniczną, zapewniającym przetwarzanie i przechowywanie, a także wysłanie i odbieranie danych poprzez sieci telekomunikacyjne. Usługi te są świadczone całą dobę 7 dni w tygodniu. Usługi świadczone z wykorzystaniem Sklepu Internetowego obejmują:
3.1.1. udostępnienie treści Strony Internetowej do przeglądania;
3.1.2. prezentacji Produktów i Usług oferowanych przez Sprzedającego.
3.2. Usługi, o których mowa w pkt. 3.1, są świadczone są nieodpłatnie.
3.3. Sprzedający dokłada wszelkich starań w celu zapewnienia, sprawności działania Sklepu Internetowego całą dobę przez 7 dni w tygodniu. W związku z powyższym Sprzedający zastrzega sobie prawo do ograniczenia dostępu do Sklepu Internetowego w celu usunięcia awarii bądź poprawy jego funkcjonalności.
3.4. W Sklepie Internetowym zostały udostępnione wizualizacje i opisy Produktów i Usług, które służą jedynie ich prezentacji.
3.5. Do korzystania ze Sklepu Internetowego niezbędny jest komputer lub urządzenie przenośne wyposażone w przeglądarkę internetową w wersji aktualnej na dzień korzystania lub wersji bezpośrednio ją poprzedzającej oraz posiadające podłączenie do Internetu.
3.6. Użytkownik ma obowiązek korzystać ze Sklepu Internetowego zgodnie z jego przeznaczeniem i zasadami współżycia społecznego. Użytkownik jest zobowiązany do powstrzymania się od jakichkolwiek działań, które mogłyby powodować zakłócenie prawidłowego funkcjonowania Sklepu Internetowego. Zabronione jest:
3.6.1. dostarczanie przez Użytkownika treści o charakterze bezprawnym, w szczególności treści naruszających przepisy obowiązującego prawa, zasady współżycia społecznego lub dobre obyczaje;
3.6.2. podejmowanie działań mających na celu wpływanie w sposób niedozwolony środkami technicznymi na funkcjonowanie lub system bezpieczeństwa Sklepu Internetowego;
3.6.3. podejmowanie działań naruszających prawa Sprzedającego lub podmiotów trzecich, w szczególności innych Użytkowników;
3.6.4. korzystanie ze Sklepu Internetowego i jego funkcjonalności w sposób sprzeczny z postanowieniami niniejszego Regulaminu lub zasadami współżycia społecznego.
4. SKŁADANIE I REALIZACJA ZAMÓWIEŃ
4.1. Do złożenia Zamówienia niezbędne jest posiadanie przez Klienta aktywnego adresu e-mail oraz podanie wymaganych danych osobowych.
4.2. Klient dokonuje wyboru Towarów, które chce zakupić, poprzez kliknięcie przy wybranym Towarze przycisku ADD TO CART. Po wybraniu wszystkich interesujących go Towarów, w celu finalizacji zamówienia należy kliknąć przycisk PAY NOW lub kliknąć na koszyk w prawym górnym rogu i wtedy wybrać EDIT BAG lub PAY NOW. Po przejściu do kolejnego okna Klient wpisuje swoje dane oraz wybiera sposób płatności. W celu finalizacji zamówienia Klient akceptuje treść regulaminu oraz klika przycisk ZAMAWIAM I PŁACĘ. Kliknięcie przycisku ZAMAWIAM I PŁACĘ jest równoznaczne ze złożeniem Sprzedającemu oferty zakupu Towarów znajdujących się w koszyku w chwili składania zamówienia.
4.3. Sprzedający ma obowiązek dostarczenia Produktów i Usług wolnych od wad fizycznych i prawnych.
4.4. Opisy Produktów i Usług wraz z cenami zamieszczone w Sklepie Internetowym stanowią zaproszenie do zawarcia umowy, nie zaś ofertę w rozumieniu Kodeksu cywilnego. Składając Zamówienie Klient składa Sprzedającemu ofertę nabycia wybranego Produktu lub Usługi za cenę i o cechach określonych w opisie Produktu lub Usługi.
4.5. Sprzedający zastrzega sobie prawo do bieżącej zmiany cen Produktów i Usług, organizowania i odwoływania akcji promocyjnych w Sklepie Internetowym oraz wprowadzenia w nich zmian. Powyższe zmiany pozostaną jednak bez wpływu na treść Zamówień złożonych przez Klientów przed dokonaniem zmian.
4.6. Klient składający Zamówienie dokonuje wyboru rodzaju i ilości poszczególnych Produktów lub Usług.
4.7. Umowa o świadczenie usług drogą elektroniczną i/lub Umowa o dostarczenie treści cyfrowych zostaje zawarta w momencie otrzymania przez Klienta wiadomości e-mail o przyjęciu przez Sprzedającego Zamówienia do realizacji. Miejscem zawarcia umowy jest siedziba Sprzedającego.
4.8. Sprzedający dokłada wszelkich starań w celu zapewnienia stałej dostępności Produktów i Usług prezentowanych w Sklepie Internetowym. W przypadku stwierdzenia niedostępności Produktu lub Usługi objętych Zamówieniem Klienta, Sprzedający niezwłocznie zawiadomi o tym Klienta. Sprzedający w przypadku dokonania przez Klienta zapłaty ceny za niedostępny Produkt lub Usługę, niezwłocznie zwróci mu całą otrzymaną sumę.
4.9. Sprzedający może oferować Klientom rabaty lub inne formy obniżenia ceny Produktów i Usług udostępniając Klientom kody rabatowe. Warunki korzystania z kodów rabatowych, w szczególności wysokość rabatu, listę Produktów i Usług, których rabat dotyczy, okres ważności kodu rabatowego, Sprzedający udostępnia Klientom wraz z kodem rabatowym. Klient, który chce skorzystać z kodu rabatowego powinien podczas składania Zamówienia wpisać kod rabatowy w przeznaczone do tego pole na formularzu Zamówienia.
5. PŁATNOŚĆ
5.1. Sprzedający umożliwia Klientom dokonywanie zapłaty za Produkty i Usługi nabyte za pośrednictwem Sklepu Internetowego poprzez serwis internetowy Przelewy 24, który jest obsługiwany przez operatora płatności: PayPro S.A. z siedzibą w Poznaniu przy ul. Kanclerskiej 15, 60-327 Poznań, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego po numerem KRS 0000347935, numer NIP 7792369887, o kapitale zakładowym wynoszącym 4.500.000,00 zł, w całości opłaconym, wpisaną do rejestru krajowych instytucji płatniczych prowadzonego przez Komisję Nadzoru Finansowego pod numerem podmiotu w UKNF IP24/2014, jako krajowa instytucja płatnicza.
5.2. Po wyborze płatności zostaje przekierowany do internetowego serwisu płatniczego Przelewy24 umożliwiającego dokonanie transakcji z wykorzystaniem sieci telekomunikacyjnej.
6. DOSTAWA
6.1. Po opłaceniu Zamówienia i potwierdzeniu przez Sprzedającego zawarcia Umowy o świadczenie usług drogą elektroniczną i/lub Umowy o dostarczenie treści cyfrowych Klient otrzyma wiadomość elektroniczną na podany przez niego adres e-mail, w której będzie znajdował się odnośnik do pobrania E-booka (w przypadku Umowy o dostarczenie treści cyfrowych) lub instrukcja logowania wraz z Hasłem Dostępu do Platformy na której będą znajdowały się Materiały Audiowizualne (w przypadku zawarcia Umowy o świadczenie usług drogą elektroniczną). W przypadku podania przez Klienta błędnego adresu e-mail, Sprzedający będzie czekać na kontakt ze strony kupującego. 
6.2. Odnośnik do pobrania E-booka będzie aktywny na zawsze od dnia jego przesłania Klientowi .
6.4. Po pobraniu E-booka Klient powinien zapisać go w bezpiecznym miejscu lub na bezpiecznym nośniku — według własnego wyboru.
6.5. Czas dostarczenia E-booka i rozpoczęcie świadczenia Usługi jest wskazywany podczas składania Zamówienia. Sprzedający dołoży wszelkich starań, aby wysyłka następowała niezwłocznie, nie później niż w terminie wskazanym podczas składania Zamówienia. W przypadku braku możliwości wysyłki Produktu lub świadczenia Usługi we wskazanym terminie, Sprzedający niezwłocznie poinformuje Klienta o tym fakcie.
7. WYMAGANIA TECHNICZNE
7.1. W przypadku zawierania przez Klienta Umowy o dostarczenie treści cyfrowych lub Umowy o świadczenie usług drogą elektroniczną do prawidłowego korzystania z Usług i Produktów wymagane są:
7.1.1. stały dostęp do Internetu;
7.1.2. poprawnie skonfigurowana przeglądarka internetowa akceptująca pliki typu Cookie – Microsoft Internet Explorer w wersji nie niższej niż 8.0 lub Mozilla Firefox w wersji nie niższej niż 3.0, Opera w wersji nie niższej niż 9.0, Google Chrome w wersji nie niższej niż 6;
7.1.3. aktywna obsługa cookies;
7.1.4. czynne i poprawnie skonfigurowane konto poczty aktywny adres e-mail;
7.1.5. programy na urządzeniu Klienta zapewniająca odczytywanie plików w formacie .pdf, .docx lub .xlsx.
9. PRAWA WŁASNOŚCI INTELEKTUALNEJ
9.1. Sprzedający niniejszym informuje Klienta, że treści zawarte w Sklepie Internetowym, takie jak materiały tekstowe, graficznie i zdjęciowe prezentowane a także treści E-booków i Materiałów Audiowizualnych objęte są ochroną prawa autorskiego, a prawa autorskie przysługują Sprzedającemu.
9.2. Bezprawne rozpowszechnianie całości lub fragmentów treści opisanych w pkt 9.1., może skutkować odpowiedzialnością cywilną lub karną osoby naruszającej prawa Sprzedającego.
10. PRAWO DO ODSTĄPIENIA OD UMOWY
10.1. Konsumentowi, z zastrzeżeniem postanowień ust. 10.9., na podstawie art. 27 ustawy o prawach konsumenta przysługuje prawo do odstąpienia od umowy zawartej na odległość w terminie 14 dni od jej zawarcia, bez podania jakiejkolwiek przyczyny. Po tym terminie uprawnienie wygasa.
10.2. W celu odstąpienia od Umowy o świadczenie usług drogą elektroniczną i/lub Umowy o dostarczenie treści cyfrowych, Konsument zobowiązany jest do złożenia oświadczenia o odstąpieniu. Konsument może złożyć oświadczenie o odstąpieniu przy pomocy wzoru formularza odstąpienia od umowy (Załącznik nr 1 do Regulaminu) – jednak korzystanie z udostępnionego wzoru nie jest obowiązkowe. Termin do złożenia oświadczenia jest zachowany jeżeli Konsument wyśle Sprzedającemu oświadczenie o odstąpieniu przed upływem wskazanego terminu.
10.3. Sprzedający niezwłocznie, lecz nie później niż w terminie 14 dni od otrzymania od Konsumenta oświadczenia o odstąpieniu od Umowy o świadczenie usług drogą elektroniczną i/lub Umowy o dostarczenie treści cyfrowych, zwróci Konsumentowi uiszczoną cenę.
10.4. Sprzedający dokona zwrotu płatności z użyciem takiej samej metody płatności, jaką wybrał Konsument uiszczając cenę Produktu, chyba że Konsument wyrazi zgodę na dokonanie zwrotu w inny sposób. Konsument nie ponosi żadnych kosztów w związku ze zwrotem płatności.
10.5. Prawo do odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w przypadkach wymienionych w art. 38 pkt. 1 – 13 Ustawy z dnia 30 maja 2014 r. o prawach konsumenta, w szczególności w przypadku umowy:
10.5.1. o świadczenie usług, jeżeli Sprzedający wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedającego utraci prawo do odstąpienia od umowy;
10.5.2. o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych, przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie oznaczono dzień lub okres wykonania usługi;
10.5.3. o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy.
10.6. Klient jest informowany o utracie prawa odstąpienia od Umowy przed rozpoczęciem świadczenia usługi i/lub dostarczenia treści cyfrowych.
10.7. Konsument może uzyskać bezpłatnie informacje o przysługujących mu uprawnieniach oraz w zakresie sporu pomiędzy nim a Usługodawcą m.in. zwracając się do powiatowego (miejskiego) rzecznika praw konsumentów, organizacji konsumenckiej np. Federacji Konsumentów, organizacji społecznej lub jednego z Wojewódzkich Inspektoratów Inspekcji Handlowej, które prowadzą mediacje i przy których działają Stałe Polubowne Sądy Konsumenckie. Informacje w zakresie praw przysługujących konsumentom znajdują się także na stronie internetowej Prezesa Urzędu Ochrony Konkurencji i Konsumentów w szczególności pod adresem:
https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php
10.8. Konsument może także złożyć swoją skargę za pośrednictwem unijnej platformy internetowej ODR (online dispute resolution), dostępnej pod adresem: http://ec.europa.eu/consumers/odr/, zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 524/2013 z dnia 21 maja 2013 r. w sprawie internetowego systemu rozstrzygania sporów konsumenckich oraz zmiany rozporządzenia (WE) nr 2006/2004 i dyrektywy 2009/22/WE (rozporządzenie w sprawie ODR w sporach konsumenckich).
10.9. Wszystkie zasady dotyczące prawa odstąpienia od umowy, zawarte w pkt 10.1 – stosuje się bezpośrednio do Przedsiębiorcy na prawach konsumenta, z zastrzeżeniem, że wzór formularza oświadczenia o odstąpieniu od umowy, o którym mowa w pkt 10.2., z którego może skorzystać Przedsiębiorca na prawach konsumenta znajduje się w Załączniku nr 3 do Regulaminu.
11. REKLAMACJE PRODUKTÓW (RĘKOJMIA)
11.1. Sprzedający odpowiada wobec Konsumenta za niezgodność Towarów z Umową Sprzedaży (rękojmia). W przypadku stwierdzenia wad Produktu lub Usługi Konsument uprawniony jest do złożenia reklamacji z tytułu rękojmi. Sprzedający zastrzega sobie jednak reklamacje dt. Literówek w tekście. Jeśli literówka zostanie znaleziona przez użytkownika, wszyscy konsumenci dostaną poprawioną wersję bez zwrotu pieniędzy. 
11.2. Sprzedający odpowiada z tytułu rękojmi za jakość oferowanych Produktów i rozpatruje reklamacje na podstawie obowiązujących przepisów o rękojmi wynikających z Kodeksu cywilnego.
11.3. Celem przyśpieszenia rozpatrzenia zgłoszenia reklamacyjnego prosimy o zawarcie w jego treści:
11.3.1. danych Konsumenta,
11.3.2. numeru Zamówienia,
11.3.3. opisu wady Produktu lub Usługi,
11.3.4. wskazanie żądanego sposobu załatwienia reklamacji (naprawa, wymiana, obniżenie ceny, zwrot gotówki).
11.4. Konsument może także skorzystać z wzoru formularza reklamacyjnego udostępnionego przez Sprzedającego (Załącznik nr 2 do Regulaminu).
11.5. Sprzedający powiadomi Konsumenta o rozpatrzeniu reklamacji złożonej z tytułu rękojmi niezwłocznie, a w przypadku, gdy reklamację złożył Konsument – nie później niż w ustawowym terminie 14 dni od daty otrzymania zgłoszenia reklamacyjnego, chyba że krótszy czas rozpatrzenia reklamacji wynika z bezwzględnie obowiązujących przepisów prawa.
11.6. Konsument może uzyskać bezpłatnie informacje o przysługujących mu uprawnieniach oraz w zakresie sporu pomiędzy nim a Sprzedającym m.in. zwracając się do powiatowego (miejskiego) rzecznika praw konsumentów, organizacji konsumenckiej np. Federacji Konsumentów, organizacji społecznej lub jednego z Wojewódzkich Inspektoratów Inspekcji Handlowej, które prowadzą mediacje i przy których działają Stałe Polubowne Sądy Konsumenckie. Informacje w zakresie praw przysługujących konsumentom znajdują się także na stronie internetowej Prezesa Urzędu Ochrony Konkurencji i Konsumentów w szczególności pod adresem:
https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php
11.7. Konsument może także złożyć swoją skargę za pośrednictwem unijnej platformy internetowej ODR (online dispute resolution), dostępnej pod adresem: http://ec.europa.eu/consumers/odr/, zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 524/2013 z dnia 21 maja 2013 r. w sprawie internetowego systemu rozstrzygania sporów konsumenckich oraz zmiany rozporządzenia (WE) nr 2006/2004 i dyrektywy 2009/22/WE (rozporządzenie w sprawie ODR w sporach konsumenckich).
11.8. Wszystkie zasady dotyczące prawa do reklamacji Towaru, zawarte w pkt 11.1 – 11.7. stosuje się bezpośrednio do Przedsiębiorcy na prawach konsumenta, z zastrzeżeniem, że wzór formularza reklamacyjnego, o którym mowa w pkt 11.4., z którego może skorzystać Przedsiębiorca na prawach konsumenta znajduje się w Załączniku nr 4 do Regulaminu.
12. OCHRONA PRYWATNOŚCI I OCHRONA DANYCH OSOBOWYCH
12.1. Zagadnienia dotyczące danych osobowych zostały uregulowane w Polityce Prywatności, która stanowi integralną część Regulaminu.
13. POSTANOWIENIA KOŃCOWE
13.1. Klient nie jest zobowiązany do złożenia jakiejkolwiek kaucji, czy udzielenia innych gwarancji finansowych Sprzedawcy.
13.2. Sprzedający zastrzega sobie prawo do zmiany treści Regulaminu i/lub Załączników do Regulaminu z ważnej przyczyny, takiej jak:
13.2.1. konieczność dostosowania treści Regulaminu do przepisów prawa mających wpływ na treść Regulamin i skutkujących koniecznością zmodyfikowania treści Regulaminu celem zachowania jego zgodności z obowiązującymi przepisami;
13.2.2. konieczność dostosowania Regulaminu do zaleceń, nakazów, postanowień, orzeczeń interpretacji, wytycznych lub decyzji uprawnionych władz publicznych;
13.2.3. rozbudowa lub zmiana funkcjonalności Sklepu Internetowego, w tym wprowadzenie nowych usług świadczonych drogą elektroniczną lub zmiana istniejących funkcjonalności Sklepu Internetowego;
13.2.4. zmiana wymagań technicznych świadczenia usług drogą elektroniczną;
13.2.5. niezbędność usunięcia nieścisłości, błędów lub omyłek pisarskich, które ewentualnie wystąpiłyby w Regulaminie;
13.2.5. zmiana danych teleadresowych, nazw, numerów identyfikacyjnych, adresów e-mail elektronicznych lub linków zamieszczonych w Regulaminie;
13.2.7. przeciwdziałanie nadużyciom;
13.2.8. poprawa obsługi Klientów;
13.2.9. zmiana procesu zawierania umów za pośrednictwem Sklepu Internetowego.
13.3. Zmiany dokonywane na podstawie pkt 13.1. nie będą naruszały praw nabytych Klientów. Sprzedający będzie informował Klientów o każdorazowej zmianie treści Regulaminu, poprzez publikację nowej wersji regulaminu w .
13.4. Treść zmienionego Regulaminu wiąże Klientów, w terminie 14 dni od dnia poinformowania ich o zmianach dokonanych w treści Regulaminu.
13.5. Zmiana treści Regulaminu pozostanie wpływu na Zamówienia złożone przed wprowadzoną zmianą.
`

const Terms = () => {
    let history = useHistory();
    return (
        <div className="termsAndPrivacy">
            <div className="termsAndPrivacyContent">
                <div className="textComponentTermsAndPrivacy">
                    <p>
                        {text}
                        <p>
                            13.6. Treść Regulaminu jest udostępniona do nieodpłatnego pobrania i zapisania przez Użytkowników i Klientów 
                            <a style={{"color":"white"}} href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:59100adc-53ad-4356-ac94-b8fc24f5c415"> TUTAJ.</a>
                        </p>
                        <p>
                            13.7. Załącznik nr 1 do Regulaminu – Formularz odstąpienia od umowy zawartej na odległość znajdziesz
                            <a style={{"color":"white"}} href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:6ddcbb52-dcf0-4587-9c06-7be9dffc8599"> TUTAJ.</a>
                        </p>
                        <p>
                            13.8. Załącznik nr 2 do Regulaminu – Formularz reklamacyjny znajdziesz .
                            <a style={{"color":"white"}} href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:6ba62f90-9021-4f94-96a0-92e5b9038f21"> TUTAJ.</a>
                        </p>
                        <p>
                            13.9. Załącznik nr 3 do Regulaminu – Formularz odstąpienia od umowy zawartej na odległość przez Przedsiębiorcę na prawach konsumenta znajdziesz .
                            <a style={{"color":"white"}} href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:df961719-8626-4180-b4d8-96034f6a0d9d"> TUTAJ.</a>
                        </p>
                        <p>
                            13.10. Załącznik nr 4 do Regulaminu – Formularz reklamacyjny dla Przedsiębiorcy na prawach konsumenta znajdziesz
                            <a style={{"color":"white"}} href="https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:fa56a455-1018-46c0-a03d-2bb18b58279f"> TUTAJ.</a>
                        </p>
                    </p>
                </div>
                <img src={backArrow} className="BackButton" onClick={() => history.goBack()}/>
            </div>
        </div>)
}

export default Terms
