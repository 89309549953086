import {Link, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import "./ShopInvoice.css";
import personalLine from "../../images/personalLine.png";
import backArrow from "../../images/backArrow.png";

const BackOrConfirmButtons = (props) => {
    let history = useHistory()
    const routeChange = () => {
        let path = "/shop"
        history.push(path);
    }
    return (
        <div className="payOrShopButtonsInvoice">
            <button className="shopMoreInvoice" onClick={routeChange}>Shop more</button>
            <button type="submit" className="payButtonInvoice">Order</button>
        </div>
    )
}

// onClick={() => {
//     // axios.post('http://localhost:1337/payment/', {"chosenItems": props.chosenItems , "email": "siema@wp.pl"})
//     axios.post('https://kate-dev-308319.ey.r.appspot.com/payment/', {"chosenItems": props.chosenItems , "email": "karolkiljan@gmail.com"})
//         .then(response => {
//             window.location.href = response.data.message.link
//         })
// }}

const ShopInvoice = (props) => {
    let history = useHistory();

    const nipValidator = (nip) => {
        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
        let sum = 0
        for (let i = 0; i < 9; i++) {
            sum += (weights[i] * Number(nip.charAt(i)))
        }
        const modulo = sum % 11
        return Number(nip.charAt(9)) === modulo
    }

    const [personalData, setPersonal] = useState({
        name: "",
        surname: "",
        email: "",
        companyName: "",
        companyNip: "",
        street: "",
        houseNum: "",
        apartNum: "",
        zipCode: "",
        city: "",
        country: "",
        rodo: false,
        invoice: false,
    })

    useEffect(() => {
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
        if (props.chosenItems.length > 0) {
            if (personalData.invoice) {
                if (personalData.companyName === "" ||
                    personalData.companyNip === "" ||
                    personalData.street === "" ||
                    personalData.houseNum === "" ||
                    personalData.zipCode === "" ||
                    personalData.city === "" ||
                    personalData.country === "") {
                    alert("We kindly ask you to fill in all the gaps if you want to receive an invoice.")
                } else {
                    const nip = personalData.companyNip.replace("-", "").replace(" ", "")
                    if (nip.length !== 10 || Number.isNaN(Number(nip)) || !nipValidator(nip)) {
                        alert('Please check that you have entered your tax identification number correctly. If it is not entered according to scheme 1112223344 or 111-222-33-44, your invoice will not be delivered to you.')
                    } else {
                        axios.post('https://backend-68zlq.ondigitalocean.app/payment',
                            {"chosenItems": props.chosenItems, "personalData": personalData, "discount": props.discount})
                            .then(response => {
                                if (response.data.message.link === 'free') {
                                    props.setShopState({chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
                                    localStorage.setItem("totalCost", 0)
                                    localStorage.setItem("chosenItems", JSON.stringify([]))
                                    localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
                                    history.push('/shop/thanks')
                                } else {
                                    props.setShopState({chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
                                    localStorage.setItem("totalCost", 0)
                                    localStorage.setItem("chosenItems", JSON.stringify([]))
                                    localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
                                    window.location.href = response.data.message.link
                                }
                            })
                        // props.setShopState({...props.shopState, chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
                        // localStorage.setItem("totalCost", 0)
                        // localStorage.setItem("chosenItems", JSON.stringify([]))
                        // localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
                    }
                }
            } else {
                axios.post('https://backend-68zlq.ondigitalocean.app/payment',
                    {"chosenItems": props.chosenItems, "personalData": personalData, "discount": props.discount})
                    .then(response => {
                        if (response.data.message.link === 'free') {
                            props.setShopState({chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
                            localStorage.setItem("totalCost", 0)
                            localStorage.setItem("chosenItems", JSON.stringify([]))
                            localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
                            history.push('/shop/thanks')
                        } else {
                            props.setShopState({chosenItems: [], totalCost: 0, discount: {"code":"","value":0}})
                            localStorage.setItem("totalCost", 0)
                            localStorage.setItem("chosenItems", JSON.stringify([]))
                            localStorage.setItem("discount", JSON.stringify({"code":"","value":0}))
                            window.location.href = response.data.message.link
                        }
                    })
            }
        } else {
            alert("You have not selected any product, if you want to make a purchase, select the product and add it to the cart.")
        }
    }
    return (
        <div className="shopInvoiceStyle">
            <div className="shopInvoiceWrapper">
                <img className="personalLine" src={personalLine}/>
                <form className="invoice" onSubmit={handleSubmit}>
                    <div className="formWrapper">
                        <div className="formRequiredPart">
                            <div>
                                <div className="formRow">
                                    <label>NAME*</label>
                                    <input type="text" value={personalData.name} onChange={(event) => {
                                        setPersonal({...personalData, name: event.target.value})
                                    }} required/>
                                </div>
                                <div className="formRow">
                                    <label>SURNAME*</label>
                                    <input type="text" value={personalData.surname} onChange={(event) => {
                                        setPersonal({...personalData, surname: event.target.value})
                                    }} required/>
                                </div>
                                <div className="formRow">
                                    <label>E-MAIL*</label>
                                    <input type="email" value={personalData.email} onChange={(event) => {
                                        setPersonal({...personalData, email: event.target.value})
                                    }} required/>
                                </div>
                            </div>
                            <div>
                                <div className="checkboxStyle">
                                    <input
                                        type="checkbox" value={personalData.rodo} onChange={(event) => {
                                        setPersonal({...personalData, rodo: event.target.checked})
                                    }} required/>
                                    <label>I have read, understand and accept the <Link to={{pathname: "/privacy"}}> Privacy Policy </Link>
                                        and <Link to={{pathname: "/terms"}}> Terms and Conditions </Link> of the challengerbykate.pl store.
                                    </label>
                                </div>
                                <div className="checkboxStyle">
                                    <input
                                        type="checkbox" value={personalData.invoice} onChange={(event) => {
                                        setPersonal({...personalData, invoice: event.target.checked})
                                    }}/>
                                    <label>I would like an invoice.</label>
                                </div>
                            </div>
                        </div>
                        <div className="formPart"
                             style={!personalData.invoice ? {"pointer-events": "none", opacity: 0.2} : {}}>
                            <p>INVOICE DATA</p>
                            <div className="formRow">
                                <label>COMPANY NAME</label>
                                <input type="text" value={personalData.companyName} onChange={(event) => {
                                    setPersonal({...personalData, companyName: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>COMPANY NIP</label>
                                <input type="text" value={personalData.companyNip} onChange={(event) => {
                                    setPersonal({...personalData, companyNip: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>STREET</label>
                                <input type="text" value={personalData.street} onChange={(event) => {
                                    setPersonal({...personalData, street: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>HOUSE NUMBER</label>
                                <input type="text" value={personalData.houseNum} onChange={(event) => {
                                    setPersonal({...personalData, houseNum: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>APARTMENT NUMBER</label>
                                <input type="text" value={personalData.apartNum} onChange={(event) => {
                                    setPersonal({...personalData, apartNum: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>ZIP CODE</label>
                                <input type="text" value={personalData.zipCode} onChange={(event) => {
                                    setPersonal({...personalData, zipCode: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>CITY</label>
                                <input type="text" value={personalData.city} onChange={(event) => {
                                    setPersonal({...personalData, city: event.target.value})
                                }}/>
                            </div>
                            <div className="formRow">
                                <label>COUNTRY</label>
                                <input type="text" value={personalData.country} onChange={(event) => {
                                    setPersonal({...personalData, country: event.target.value})
                                }}/>
                            </div>
                        </div>
                    </div>
                    <BackOrConfirmButtons chosenItems={props.chosenItems}/>
                </form>
            </div>
            <img src={backArrow} className="BackButton" onClick={() => history.push("/shop/bag")}/>
        </div>
    )
}

export default ShopInvoice;
