import "./SendMessage.css"
import {useHistory} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import messageThanks from "../../images/messageThanks.png"
import backArrow from "../../images/backArrow.png";
// import thankImage from "../../images/thankImage.png"
const SendMessage = () => {
    const [email, setEmail] = useState("")
    const [topic, setTopic] = useState("")
    const [message, setMessage] = useState("")
    const [sent, setSent] = useState(false)
    let history = useHistory();

    const submitMessage = () => {
        setSent(true)
        axios.post('https://backend-68zlq.ondigitalocean.app/contact', {"email": email , "topic": topic, "message": message})
    }
    return (
        <div className="messageStyle messageBackground">
            <div className="messageWrapper">
                {
                    sent?
                        (
                            <img className="thankImage" src={messageThanks} alt=''/>
                        ):
                        (
                    <div className="formStyle">
                        <div className="inputWrapper">
                        <label className="inputName">
                        YOUR E-MAIL
                        </label>
                        <input className="inputSmaller" type="email" value={email}
                        onChange={(event) => setEmail(event.target.value)}/>
                        </div>
                        <div className="inputWrapper">
                        <label className="inputName">
                        TOPIC
                        </label>
                        <input className="inputSmaller" type="text" value={topic}
                        onChange={(event) => setTopic(event.target.value)}/>
                        </div>
                        <div className="inputWrapper">
                        <label className="inputName">
                        MESSAGE
                        </label>
                        <textarea className="inputBigger" value={message}
                        onChange={(event) => setMessage(event.target.value)}/>
                        </div>
                        <input type="button" className="submitStyle" value="Submit"
                        onClick={submitMessage}/>
                    </div>)
                }
            </div>
            <img src={backArrow} className="BackButton" onClick={() => history.push("/")} />
        </div>
    )
}

export default SendMessage
